import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Box, PageContent, Heading, Paragraph } from "grommet";

import Page from "../components/page";
import UL from "../components/ul";
import SEO from "../components/seo";

const ULTitle = ({ children, ...others }) => {
  return <span {...others}>{children}</span>;
};

const ULTitleStyle = styled(ULTitle)`
  text-decoration: underline;
`;

const IndexPage = () => {
  return (
    <Page kind="narrow">
      <SEO
        title="Les pensions | Les écuries du cerf pension pour cheval"
        description="Liste des pensions disponibles aux écuries du cerf, écuries de propriétaires à Gaudreville-la-Rivière entre Evreux et Conches-en-Ouche dans l'eure 27"
      />
      <PageContent>
        <Heading textAlign="center" alignSelf="center">
          Les pensions pour votre cheval
        </Heading>
        <Paragraph fill>
          Toutes les pensions sont modulables selon les besoins de votre équidé.
        </Paragraph>
        <UL>
          <ULTitleStyle>Pension retraite : 350€/mois</ULTitleStyle>
          <li>Box paille</li>
          <li>2 à 3 repas de granulés par jour (selon le besoin du cheval)</li>
          <li>2 repas foin par jour</li>
          <li>
            accès aux <Link to="/infrastructures">infrastructures</Link>
          </li>
          <li>pansage quotidien de votre équidé</li>
          <li>sortie quotidienne au paddock</li>
        </UL>
        <UL>
          <ULTitleStyle>Pension remise en forme : 400€/mois</ULTitleStyle>
          <li>Box paille</li>
          <li>2 à 3 repas de granulés par jour (selon le besoin du cheval)</li>
          <li>2 repas foin par jour</li>
          <li>
            accès aux <Link to="/infrastructures">infrastructures</Link>
          </li>
          <li>pansage quotidien de votre équidé</li>
          <li>sortie quotidienne au paddock ou au marcheur</li>
          <li>2 sorties en longe ou monté par semaine</li>
        </UL>
        <UL>
          <ULTitleStyle>Pension convalescence : 420€/mois</ULTitleStyle>
          <li>Box paille</li>
          <li>Repas en fonction de l'équidé</li>
          <li>
            accès aux <Link to="/infrastructures">infrastructures</Link>
          </li>
          <li>
            pansage et soins (traitement, douche, argile) quotidiens de votre
            équidé
          </li>
          <li>
            sortie quotidienne en fonction de la convalescence (pas en main,
            marcheur, longe, paddock)
          </li>
        </UL>
        <UL>
          <ULTitleStyle>Pension sportive : 450€/mois</ULTitleStyle>
          <li>Box paille</li>
          <li>2 à 3 repas de granulés par jour (selon le besoin du cheval)</li>
          <li>2 repas foin par jour</li>
          <li>
            accès aux <Link to="/infrastructures">infrastructures</Link>
          </li>
          <li>pansage quotidien de votre équidé</li>
          <li>sortie quotidienne au paddock ou au marcheur</li>
          <li>4 sorties en longe ou monté par semaine</li>
        </UL>
        <UL>
          <ULTitleStyle>Pension Horse-Sitting : 20€/nuit</ULTitleStyle>
          <li>Box paille ou copeaux</li>
          <li>2 à 3 repas granulés par jour</li>
          <li>2 repas foin par jour</li>
          <li>sortie au paddock ou au marcheur</li>
          <li>sortie en longe ou monté</li>
          <li>pansage quotidien de votre équidé</li>
        </UL>

        <Box direction="row" align="center" gap="small">
          <div>🖊️</div>
          <Paragraph fill>
            Pension box copeaux : +50€
            <br />
            Pour toute demande particulière, un prix peut être établi au cas par
            cas (vous pouvez nous contacter via{" "}
            <Link to="/contact">la page de contact</Link>).
          </Paragraph>
        </Box>
        <Box direction="row" align="center" gap="small">
          <div>🩺</div>
          <Paragraph fill>
            Les frais vétérinaires, les frais de maréchaleries ainsi que les
            frais médicaux (dentiste équin, ostéopathe, ...) sont aux frais des
            propriétaires.
          </Paragraph>
        </Box>
        <Box direction="row" align="center" gap="small">
          <div>👩‍🎓</div>
          <Paragraph fill>
            Possibilité de perfectionnement avec votre équidé en cours
            particulier avec un cavalier professionel (Edouard Coral) ou un
            moniteur indépendant (<Link to="/commerce">Victoria Goupillot</Link>
            ).
          </Paragraph>
        </Box>
      </PageContent>
    </Page>
  );
};

export default IndexPage;
